.GreyRounded {
  background-color: #ECECEC;
  padding: 10px;
  border-radius: 10px;
}

@media (min-width: 992px) {
    .inv-image-block-container {
      /* Use flex box with row orrientation */
      display: flex;
      flex-direction: column;
      /* Vertically center children */
      align-items: center;
      margin:0px 0px 0px 0px;
      padding-bottom: 75rem;
      max-height: 850px;
    }
  
    .inv-text-block {
      /* Cause text block to tranlate by half width to
      overlap the image */
      max-width: 60rem;
      background: white;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      border-left: 0px solid #2d419a; 
    }
  
    .inv-outer {
      transform: translate(0%, -40%);
      max-width: 60rem;
      padding: 1rem;
      background: white;
      text-align: center;
    }
  
    .inv-content-img {
      max-width: 100%;
    }

    .title-outer {
        transform: translate(0%, -290%);
        max-width: 60rem;
        padding: 1rem;
        color: white;
        font-size: 4rem;
        text-align: center;
    }
  }
  
  
  /* Mobile */
  
  @media (max-width: 992px) {
    .inv-image-block-container {
        /* Use flex box with row orrientation */
        display: flex;
        flex-direction: column;
        /* Vertically center children */
        align-items: center;
    }
    .inv-text-block {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      border-left: 3px solid #2d419a; 
    }
  
    .inv-content-img {
      max-width: 100%;
      padding-top: 3rem;
      padding-bottom: 1rem;
      padding-left: 0px;
      padding-right: 0px;
    }

    .title-outer {
        transform: translate(0%, -300%);
        max-width: 60rem;
        color: white;
        font-size: 2rem;
        text-align: center;
    }
  }