.visionContainer {
    text-align: center;
}

.rowAbout {
    padding-bottom: 50px;
}

.card {
    border: 0px;
}

.about-card-img-top {
    border-radius: 50%;
    height: 250px;
    width: 250px;
}

.visionHr {
    height: 1px;
        background-color: black;
        border: none;
}

@media (min-width: 992px) {
    .about-image-block-container {
      /* Use flex box with row orrientation */
      display: flex;
      flex-direction: column;
      /* Vertically center children */
      align-items: center;
      margin:0px 0px 0px 0px;
      padding:0px 0px 0px 0px;
      max-height: 675px;
    }
  
    .about-text-block {
      /* Cause text block to tranlate by half width to
      overlap the image */
      max-width: 60rem;
      background: white;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      border-left: 0px solid #2d419a; 
    }
  
    .about-outer {
      transform: translate(0%, -50%);
      max-width: 60rem;
      padding: 1rem;
      background: white;
      text-align: center;
    }
  
    .about-content-img {
      min-width: 100%; 
      max-height: 500px;
    }
  }
  
  
  /* Mobile */
  
  @media (max-width: 992px) {
    .about-image-block-container {
        /* Use flex box with row orrientation */
        display: flex;
        flex-direction: column;
        /* Vertically center children */
        align-items: center;
    }
    .about-text-block {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      border-left: 3px solid #2d419a; 
    }
  
    .about-content-img {
      max-width: 100%;
      padding-top: 3rem;
      padding-bottom: 1rem;
      padding-left: 0px;
      padding-right: 0px;
    }
  }