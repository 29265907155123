@media (min-width: 767px) { 
    .carousel-item > img { 
        min-width: 100%;
        max-width: none;
        min-height: 512px;
        max-height: none;
        margin: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .carousel-item {
        height: 500px;
        position: relative;
    }
}

@media (max-width: 767px) { 
    .carousel-item > img { 
        min-width: 100%;
        height: 225px;
        max-width: none;
        margin: auto;
    }
    .ch {
        display: inline;
    }
}