.navbar {
    padding: 0px;
    font-size: 18px;
}

.navbar-toggler {

}

.navbar-brand {
    font-size: 1.2em;
}
.LogoBlue {
    color: #2d419a;
}

.LogoYellow {
    background-color: #f9a11c;
}

.NavLogo {
    padding-top: 10px;
    padding-bottom: 5px;
}

.logoText {
    font-size: 32px;
    font-weight: 500;
    font-family: "MS Mincho";
    vertical-align: baseline;
    letter-spacing: 2px;
}

.navbar-brand {
    font-size: 32px;
}

.navbar-light .navbar-nav .nav-link {
    color: black;
    padding-left: 20px;
    &:hover {
        color: black;
    }
}

$duration: .2s;
$distance: 8px;
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);

$animate: all 0.2s ease-in-out;

#examples {
    
    .example {
      .hover {
        text-align: center;
        margin: 0 auto;
        padding: 0;
        transition: $animate;
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          width: 0px;
          height: 5px;
          margin: 5px 0 0;
          transition: $animate;
          transition-duration: 0.5s;
          opacity: 0;
          background-color: darken(#2d419a,5%);
        }
        &.hover-1 {
          &:before,
          &:after {
            left: 0;
          }
        }
        &.hover-2 {
          &:before,
          &:after {
            right: 0;
          }
        }
        &.hover-3 {
          &:before {
            left: 50%;
          }
          &:after {
            right: 50%;
          }
        }
        &.hover-4 {
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
        &.hover-5 {
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
      }
      &:hover {
        cursor: pointer;
        .hover {
          &:before,
          &:after {
            width: 100%;
            opacity: 1;
          }
          &.hover-3,
          &.hover-4 {
            &:before,
            &:after {
              width: 50%;
            }
          }
        }
      }
    }
    .example-yellow {
      .hover {
        text-align: center;
        margin: 0 auto;
        padding: 0;
        transition: $animate;
        position: relative;
        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          width: 0px;
          height: 5px;
          margin: 5px 0 0;
          transition: $animate;
          transition-duration: 0.5s;
          opacity: 0;
          background-color: darken(#f9a11c,5%);
        }
        &.hover-1 {
          &:before,
          &:after {
            left: 0;
          }
        }
        &.hover-2 {
          &:before,
          &:after {
            right: 0;
          }
        }
        &.hover-3 {
          &:before {
            left: 50%;
          }
          &:after {
            right: 50%;
          }
        }
        &.hover-4 {
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
      }
      &:hover {
        cursor: pointer;
        .hover {
          &:before,
          &:after {
            width: 100%;
            opacity: 1;
          }
          &.hover-3,
          &.hover-4 {
            &:before,
            &:after {
              width: 50%;
            }
          }
        }
      }
    }
  }