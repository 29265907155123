.breadcrumb {
  background-color: rgba(211,211,211, 0);
  padding-bottom: 0px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.carouselcontainer {
  width:100%;
  max-width:1000px;
}

.icon {
  height: 50px;
  width: 50px;
  padding-top: 0px;
  float: left;
}

h4 {
  padding-top: 10px;
}

.subhead {
  color: #2d419a;
}

.newslettercontainer {
    border-radius: 20px;
    padding: 15px;
    border: 5px solid rgba(45,65,154, 1);
    border-style: solid;
}

@media (min-width: 767px) { 
  .emailbox {
    padding-left: 300px;
    padding-right: 300px;
  }
}

.btn {
  background-color: #2d419a;
}

a {
  color: black;
}

a:hover{
  color: #2d419a;
  text-decoration: inherit;
}

h4 {
  padding-bottom: 0px;
}

@media (max-width: 767px) { 
  .col-12 {
    padding-bottom: 30px;
  }
}

.row {
  padding: 0px;
}

.comptext {
  text-align: center;
  font-size: 26px;
}

.compTagline {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.content-container {
  border-radius: 25px;
  border: 2px solid #2d419a ;
  padding: 20px;
  width: 200px;
  height: 150px;
}

.underline {
  border-bottom: 3px solid currentColor;
  display: inline-block;
  line-height: 1.5;
  text-shadow:
    2px 2px white,
    2px -2px white,
    -2px 2px white,
    -2px -2px white;
}

.underlineBlue {
  border-bottom-color: #2d419a;
}

.underlineYellow {
  border-bottom-color: #f9a11c;
}

/* Main Data Container */
@media  (min-width:1025px) {
  .image-block-container {
    /* Use flex box with row orrientation */
    display: flex;
    flex-direction: column;
    /* Vertically center children */
    align-items: center;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    max-height: 900px;
  }

  .text-block {
    /* Cause text block to tranlate by half width to
    overlap the image */
    max-width: 40rem;
    background: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 3px solid #2d419a;
    /*border-left: 3px solid #2d419a;*/
  }

  .outer {
    transform: translate(40%, -50%);
    max-width: 40rem;
    padding: 1rem;
    background: white;
  }

  .outerWhite {
    transform: translate(-30%, -30%);
    max-width: 40rem;
    padding: 1rem;
    background: white;
  }

  .left-overlap .content-img {
    /* If left overlap case, then change the ordering
    of the img to set it after the adjacent text block */
    order: 0;
  }

  .left-overlap .left-outer {
    /* Translate text block in opposite direction for
    left overlap case */
    transform: translate(-30%, -50%);
    max-width: 80rem;
    padding: 1rem;
    background: white;
  }

  .content-img {
    max-width: 100%;
    min-width: 80%;
    max-height: 650px;
  }

  .sloganImg {
    width: 75%;
    padding: 0px;
  }

  .contPad {
    padding-top: 50px;
    padding-bottom: 110px;
  }
}

/* Ipad */
@media (min-width:961px) and (max-width:1024px)  {
  .image-block-container {
    /* Use flex box with row orrientation */
    display: flex;
    flex-direction: column;
    /* Vertically center children */
    align-items: center;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    max-height: 900px;
  }

  .text-block {
    /* Cause text block to tranlate by half width to
    overlap the image */
    max-width: 40rem;
    background: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 3px solid #2d419a;
    /*border-left: 3px solid #2d419a;*/
  }

  .outer {
    transform: translate(20%, -30%);
    max-width: 40rem;
    padding: 1rem;
    background: white;
  }

  .outerWhite {
    transform: translate(-20%, -30%);
    max-width: 40rem;
    padding: 1rem;
    background: white;
  }

  .left-overlap .content-img {
    /* If left overlap case, then change the ordering
    of the img to set it after the adjacent text block */
    order: 0;
  }

  .left-overlap .left-outer {
    /* Translate text block in opposite direction for
    left overlap case */
    transform: translate(-30%, -50%);
    max-width: 80rem;
    padding: 1rem;
    background: white;
  }

  .content-img {
    max-width: 100%;
    min-width: 100%;
    max-height: 650px;
  }

  .contPad {
    padding-top: 50px;
    padding-bottom: 110px;
  }

    .sloganImg {
    width: 75%;
    padding: 0px;
  }
}


/* Mobile */

@media (max-width: 992px) {
  .image-block-container {
      /* Use flex box with row orrientation */
      display: flex;
      flex-direction: column;
      /* Vertically center children */
      align-items: center;
  }
  .text-block {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 3px solid #2d419a; 
  }

  .content-img {
    max-width: 100%;
    padding-top: 3rem;
    padding-bottom: 1rem;
    padding-left: 0px;
    padding-right: 0px;
  }

  .sloganmobile{
    text-align: center;
  }

  .sloganImg {
    width: 100%;
    padding: 0px;
  }

  .contPad {
    padding-top: 25px;
    padding-bottom: 0px;
  }
}

.card-text {
  white-space: pre-wrap;
}