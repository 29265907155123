.footercontainer {
    max-width: 100%;
}

.frow {
    padding-top: 30px;
    padding-bottom: 30px;
}

.footerrow {
    padding-top: 0px;
    padding-bottom: 0px;
    color: grey;
}

.footera {
    color: grey;
}

.flink {
    padding-bottom: 5px;
    font-size: 16px;
}

.pfoot {
    white-space: nowrap;
}

.fNavLogo {
    height: 200px;
    width: 200px;
    padding-top: 0px;
    float: left;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  
  .separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .separator:not(:empty)::after {
    margin-left: .25em;
  }