.App {
  text-align: center;
}

.App-header {
  background-color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.Logo {
  max-width: 25%;
}
